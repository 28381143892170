/* plus-jakarta-sans-regular - latin */
@font-face {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 400;
    src: local(''),
    url('./fonts/plus-jakarta-sans-v2-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./fonts/plus-jakarta-sans-v2-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* plus-jakarta-sans-800 - latin */
@font-face {
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 800;
    src: local(''),
    url('./fonts/plus-jakarta-sans-v2-latin-800.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('./fonts/plus-jakarta-sans-v2-latin-800.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
